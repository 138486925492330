.odd {
    background-color: #f7f7f7;
}

.even {
    background-color: #f7ebef;
}

.menu_parent {
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 78px;
    padding: 0 10px;
    font-size: 15px;
    font-weight: 500;
    height: 100%;
    align-content: center;
}

.mp_orange {
    background: var(--theme_orange);
}
.mp_blue {
    background: var(--theme_blue);
}
.mp_original {
    background: var(--new_theme_original);
}

.item {
    grid-template-columns: auto 1fr;
    /* height: 49px; */
    align-items: center;
    margin-bottom: 10px;
    font-size: 15px;
    /* border: 1px solid #ebebeb; */
    border-radius: 5px;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.2),  /* Right shadow */
    0px 4px 4px -2px rgba(0, 0, 0, 0.2);
}

.cater_label{
    /* font-size: 10px;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    color: white;
    background: var(--theme_oringinal);
    padding: 2px 4px;
    border-radius: 3px;
    margin-left: auto; */
    font-size: 10px;
    width: -moz-fit-content;
    width: fit-content;
    background: red !important;
    position: absolute;
    color: white;
    background: var(--theme_oringinal);
    top: -16px;
    right: -3px;
    padding: 2px 4px;
    border-radius: 3px;
}
.cater_item {
    color: #6a6a6a;
    text-decoration: none;
    margin-block: 10px;
}

.cater_item:hover {
    cursor: pointer;
}

.ci_orange:hover {
    color: var(--theme_hover_orange);
}
.ci_blue:hover {
    color: var(--theme_hover_blue);
}
.ci_original:hover {
    color: var(--theme_hover_original);
}

.menu_parent {
    display: block;
}
.c_m_left {
    display: none;
}
.cili_wrapper {
    display: none;
}

.gridinfo {
    height: 95%;
    /* border: 1px solid #ebebeb;
    box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2); */
}
.gridpadding {
    padding-inline: 0px !important;
    /* margin-right: 10px; */
    padding-top: 20px;
}
.gridluntan {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    padding-bottom: 20px;
}
.gridluntandetails {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    box-shadow:     2px 0px 4px rgba(0, 0, 0, 0.2),  /* Right shadow */
    0px 4px 4px -2px rgba(0, 0, 0, 0.2); /* Bottom shadow */   ;
    border-radius: 10px;
    padding-block: 5px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 0;
}
.imagepadding {
    padding-inline: 25px;
}

.menu_grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 8px;
    /* margin-block: 20px; */
}

@media only screen and (max-width: 600px) {
    .imagepadding {
        padding-inline: 20px;
    }
    .gridluntan {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
    .gridluntandetails {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        box-shadow:     2px 0px 4px rgba(0, 0, 0, 0.1),  /* Right shadow */
        0px 4px 4px -2px rgba(0, 0, 0, 0.1); /* Bottom shadow */   ;
        border-radius: 5px;
        padding-block: 5px;
        margin-top: 10px;
        font-size: 15px;
    }
    .gridpadding {
        padding-inline: 5px !important;
        padding-top: 10px;
        /* margin-inline: 10px; */
    }
    .gridinfo {
        /* border: 1px solid #ebebeb;
        box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.2); */
        font-size: 12px;
    }
    .item>div:nth-child(2) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* gap: 5px; */
        column-gap: 5px;
        /* row-gap: 10px; */
        font-weight: 500;
        margin-block: 10px;
    }
    .c_m_left {
        padding-left: 10px;
        text-align: center;
        margin: 0 auto;;
    }
    .c_m_left > div {
        margin-top: 5px;
    }
    .item {
        /* height: 58px; */
        font-size: 12px;
        height: auto;
    }
    .menu_parent {
        display: block;
        color: #fff;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        min-width: 1px; /* Approximate width for two characters */
        width: 32px;
        white-space: normal; /* Allows wrapping */
        word-wrap: break-word; /* Breaks long words if needed */
        text-align: center; /* Center-aligns text for better appearance */
    }
    .c_m_left {
        display: block;
    }
    .h140 {
        height: 146px !important;
        grid-template-columns: 1fr !important;
        overflow: hidden;
    }
    .site_cili_img {
        object-fit: fill;
        overflow: hidden;
        height: 146px;
        width: 100%;
     }
     .cili_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-height: 146px;
     }
}