.containervip{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
}

.bannerTop{
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 5px;
}

.gridrowsvip{
    display: grid;
    grid-template-rows: repeat(3, minmax(0, 1fr));
    gap: 10px;
}
.containerbenefit{
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 8px;
}
.gridbenefit{
    display: grid;
    grid-template-columns: 50px 2fr;    
    gap: 8px;
}
.titlewrapper{
    margin: 12px 0 !important;
    margin-bottom: 30px !important;
}
.benefitimgsize{
    width: 30px;
    height: 34px;
}
.vipitemwrapper{
    text-decoration: none;
    overflow: hidden;
    grid-column: span 1 / span 1;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 15px 20px 0 20px;
    gap: 20px;
    height: 150px;
    position: relative;
    border-radius: 10px;
}
.box {
    -webkit-mask: /*4*/
       linear-gradient(#fff 0 0) padding-box, 
       linear-gradient(#fff 0 0);
  }
.svvipitemwrapper{
    padding: 10px;
}
.vipiconv2{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}
.vipicon{
    position: absolute;
    right: 0px;
    bottom: 5px;
}
.vipitemtitle{
    display: flex;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
}
.vipitemtitlewrapperv2{
    /* display: flex;
    gap: 10px; */
}
.vipitemtitlev2{
    font-size: 20px;
    font-weight: 700;
    /* color: white; */
    /* border-bottom: 2px solid white; */
    padding-bottom: 4px;
    width: fit-content;
}
.viplabel{
    background: linear-gradient(90deg, #FD9657 0%, #FF0000 100%);
    color: white;
    border-radius: 20px;
    padding: 3px 15px;
    width: fit-content;
    font-size: 12px;
}
.viplabelv2{
    height: max-content;
    padding: 5px 6px 5px 6px;
    border-radius: 20px 20px 20px 20px;
    background: linear-gradient(90deg, #FD9657 10%, #FF0000 100%);
    color: white;
    width: fit-content;
    font-size: 12px;
}
.vipevent{
    max-width: 65%;
    color: red;
    font-size: 16px;
    font-weight: 500;
}
.vipprice{
    font-size: 16px;
    font-weight: 500;
}
.vippricev2{
    font-size: 24px;
    font-weight: 600;
    color: white;
}
.svvippricev2{
    font-size: 22px !important;
    display: flex;
    gap: 8px;
}
.vippriceyuanv2{
    font-size: 14px;
}
.vipoldprice{
    padding-top: 5px;
    font-size: 12px;
    font-weight: normal;
    text-decoration: line-through;
}
.vipoldpricev2{
    color: #717171;
    padding-top: 5px;
    font-size: 14px;
    font-weight: normal;
    text-decoration: line-through;
    text-decoration-color: #717171;
}
.svvipoldpricev2{
    font-size: 12px !important;
}
.vip_item {
    background: #f4dfc2;
    border-radius: 10px;
    color: #333;
    cursor: pointer;
    height: 63px;
    /* margin: 5px 0; */
    margin-bottom: 5px;
    padding: 5px 15px;
    grid-template-columns: 1fr auto;
}
.vip_item_event {
    background: #f4dfc2;
    border-radius: 0 0 10px 10px;
    color: #333;
    cursor: pointer;
    height: 63px;
    /* margin: 5px 0; */
    margin-bottom: 5px;
    padding: 5px 15px;
    grid-template-columns: 1fr auto;
}
.vip_item:hover {
    background-color: #f3ba69;
}
.top {
    width: 100%;

}
.vip_item_title {
    font-size: 16px;
    font-weight: 700;
    padding-top: 5px;
}
.activitymark{
    font-size: 13px;
    font-weight: bold;
    padding: 13px 20px;
    /* background: #00000026; */
    color: #717171;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 10px 10px;
    width: -webkit-fill-available;
    left: 0;
}
.vip_old_price {
    color: #666;
    font-size: 12px;
    text-decoration: line-through;
}
.red_p {
    color: #e32647;
    font-size: 20px;
    font-weight: 700;
}
.buy_main {
    top: 20px;
    left: 0;
    right: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    text-align: center;
}

.smallView {
    left: 0px;
    transform: none;
    width: 100% !important;
}
.importanttext{
    color: red;
    font-weight: 600;
    padding-bottom: 15px;
}
.mobileimportanttext{
    color: red;
    font-weight: 600;
    padding-bottom: 15px;
    font-size: 12px;
}
.postwrapper{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 10px;
}
.mobilepostwrapper{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-bottom: 10px;
}
.postwrapper>div, .mobilepostwrapper>div{
    grid-column: span 1 / span 1;
}
.n_1 {
    color: #c49470;
    font-size: 24px;
    font-weight: 700;
    line-height: 45px;
}
.n_2 {
    background: #eda64e;
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
    padding: 4px 0;
    text-align: center;
    width: 75%;
}
.n_3 {
    background: #FF647C;
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
    padding: 4px 0;
    text-align: center;
    width: 75%;
    margin-top: 10px;
    cursor: pointer;
}
.smallView > div > .buy_main {
    width: 100% !important;
}

.containervipsmallview{
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.title {
    font-size: 25px;
}

.content {
    font-size: 16px;
}

@media only screen and (max-width: 600px) {
    .gridbenefit{
        gap: 2px;
    }
    .bannerTop{
        display: none;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .containerbenefit{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        /* gap: 10px; */
    }
    .title {
        font-size: 22px;
    }
    .content {
        font-size: 13px;
    }
    .vipitemtitlev2{
        font-size: 18px;
    }
    .viplabelv2{
        font-size: 12px;
    }
    .vippricev2{
        font-size: 20px;
    }
    .vipoldpricev2{
        font-size: 12px;
    }
    
    .vipevent{
        max-width: 55%;
    }
    .vipicon{
        bottom: 2px;
        right: -46px;
    }
    .vipitemwrapper{
        flex: 0 0 300px; /* Fixed width for items */
        box-sizing: border-box; /* Ensure padding and border are included in the width */
    }
    .containervip{
        display: grid; /* Use flexbox instead of grid for horizontal layout */
        /* overflow-x: auto; */
        grid-template-columns: repeat(1, minmax(0, 1fr));
        width: 100%;
        /* width: 350px; */
        gap: 10px; /* Space between items */
    }
        .postwrapper, .mobilepostwrapper{
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        .postwrapper > div > img, .mobilepostwrapper > div > img{
            height: auto !important;
        }
        .n_1 {
            line-height: 25px;
        }
        .buy_main {
            width: 100%;
            transform: none;
            left: 0%;
            display: grid;
            gap: 17px;
        }
  }